import React from 'react';
import { AppBar, Toolbar, Typography, Link, useTheme, Button } from '@material-ui/core';
import { ROUTES, NAV_LINKS, LOGIN_URI } from 'config/Nav';

/*
 * A text representation generated of every link found in the project.
 *
 */

const NavLink = (props: any) => {
    const { to, name } = props;
    const theme = useTheme();

    return (
        <Link key={to} href={to} style={{ color: 'white', padding: theme.spacing(2) }}>
            <Typography>{name}</Typography>
        </Link>
    );
};

const NavBar = (props: any) => {
    return (
        <div>
            <AppBar position={'static'} color={'secondary'}>
                <Toolbar
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Link href={ROUTES.HOME.to}>
                        <Typography variant={'h5'}>cannobit</Typography>
                    </Link>
                    <nav
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {NAV_LINKS.map((link) => (
                            <NavLink {...link} />
                        ))}
                        {/* <Button
                            variant={'contained'}
                            onClick={() => window.location.assign(LOGIN_URI)}
                            color={'primary'}
                            size={'small'}
                        >
                            Login
                        </Button>
                        <Button
                            variant={'outlined'}
                            onClick={() => window.location.assign(LOGIN_URI)}
                            color={'primary'}
                            size={'small'}
                        >
                            Signup
                        </Button> */}
                    </nav>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;
