export const LOGIN_URI = `https://${process.env.auth0Domain}/authorize?client_id=${process.env.auth0ClientId}&response_type=code&redirect_uri=${process.env.auth0Redirect}&scope=openid%20offline_access`;

export const ROUTES = {
    HOME: {
        to: '/',
        name: 'Home',
        external: false,
    },
    HOW_IT_WORKS: {
        to: '/how-it-works',
        name: 'How It Works',
        external: false,
    },
    ABOUT: {
        to: '/about',
        name: 'About',
        external: false,
    },
    PRICING: {
        to: '/pricing',
        name: 'Pricing',
        external: false,
    },
    CONTACT: {
        to: '/contact',
        name: 'Contact',
        external: false,
    },
    DEVPANEL: {
        to: '/dev-panel',
        name: 'Developer',
        external: false,
    },
    LOGIN: {
        to: LOGIN_URI,
        name: 'Login',
        external: true,
    },
};

export const NAV_LINKS = [
    ROUTES.HOME,
    ROUTES.HOW_IT_WORKS,
    ROUTES.ABOUT,
    ROUTES.PRICING,
    ROUTES.CONTACT,
    ROUTES.DEVPANEL,
];
